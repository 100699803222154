// Toggle Dark Mode
if (localStorage.getItem('dark')) {
    document.documentElement.classList.add('dark');
} else {
    document.documentElement.classList.remove('dark');
}

const point = document.getElementById('waypoint');

window.addEventListener('load',function(){
    if (typeof (point) != 'undefined' && point != null) {
        // Nav Waypoints
        const waypoint = new Waypoint({
            element: document.getElementById('waypoint'),
            handler: function (direction) {
                if (direction === 'down') {
                    document.getElementById('header-logos').classList.remove('lg:pt-6', 'lg:pb-6');
                    document.getElementById('logo-large').classList.add('hidden');
                    document.getElementById('logo-small').classList.remove('hidden');
                } else {
                    document.getElementById('header-logos').classList.add('lg:pt-6', 'lg:pb-6');
                    document.getElementById('logo-large').classList.remove('hidden');
                    document.getElementById('logo-small').classList.add('hidden');
                }
            },
        })
    }
});

const textSizes = ['text-sm', 'text-base', 'text-lg'];

const increase = document.getElementById('increase-text');
window.addEventListener('load',function(){
    if (typeof (increase) != 'undefined' && increase != null) {
        increase.addEventListener('click', function (e) {
            e.preventDefault();
            const elements = document.querySelectorAll('.resizeable');
            elements.forEach(element => {
                element.classList.forEach(token => {
                    if (token.startsWith('text-')) {
                        const index = textSizes.indexOf(token);
                        if (index !== -1 && index + 1 <= 2) {
                            const replacement = textSizes[index + 1];
                            element.classList.replace(token, replacement);
                        }
                    }
                })
            });
            return false;
        });
    }
});

const decrease = document.getElementById('decrease-text');
window.addEventListener('load',function(){
    if (typeof (increase) != 'undefined' && increase != null) {
        decrease.addEventListener('click', function (e) {
            e.preventDefault();
            const elements = document.querySelectorAll('.resizeable');
            elements.forEach(element => {
                element.classList.forEach(token => {
                    if (token.startsWith('text-')) {
                        const index = textSizes.indexOf(token);
                        if (index !== -1 && index - 1 >= 0) {
                            const replacement = textSizes[index - 1];
                            element.classList.replace(token, replacement);
                        }
                    }
                })
            });
            return false;
        });
    }
});

function handleMenuParentInteraction(e) {
    e.preventDefault();
    const el = e.target;
    const target = el.dataset.target;
    document.querySelectorAll('.mobile-menu-child').forEach(function (child) {
        child.classList.add('hidden');
    });
    if (el.innerHTML.trim() === '+') {
        el.innerHTML = '-';
        document.getElementById(target).classList.remove('hidden');
    } else {
        el.innerHTML = '+';
        document.getElementById(target).classList.add('hidden');
    }
}
window.addEventListener('load',function(){
    document.querySelectorAll('.mobile-menu-parent').forEach(function (item) {
        item.addEventListener('click', handleMenuParentInteraction);
        item.addEventListener('touch', handleMenuParentInteraction);
    });
});
function handleShowSocialIconInteraction(e) {
    e.preventDefault();
    const target = document.getElementById(e.target.parentNode.dataset.target) || document.getElementById(e.target.dataset.target);
    if (target.classList.contains('hidden')) {
        target.classList.remove('hidden');
    } else {
        target.classList.add('hidden');
    }
}

window.addEventListener('load',function(){
    document.querySelectorAll('.show-social-icons').forEach(function (item) {
        item.addEventListener('click', handleShowSocialIconInteraction);
        item.addEventListener('touch', handleShowSocialIconInteraction);
    });

    document.querySelectorAll('.podcast-mini-player').forEach(function (item) {
        item.addEventListener('click', handlePlayPodcastMini);
        item.addEventListener('touch', handlePlayPodcastMini);
    });
});